<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin phiếu cân kho'">
          <template v-slot:preview>
            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-3"
              >
                <template>
                  <span>Kho:</span>
                  <span class="text-danger">*</span>
                </template>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  :suggestions="filteredOptions"
                  @selected="onSelectedStore"
                  :limit="200"
                  @input="onInputChange"
                  :input-props="inputPropStore"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchStore"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>
            </div>
            <b-row>
              <b-col md="6">
                <b-form-group id="input-group-1" label-for="input-1">
                  <template>
                    <span>Ghi chú:</span>
                  </template>
                  <b-form-textarea
                    size="sm"
                    id="textarea1"
                    v-model="description"
                    :placeholder="'Thêm nội dung ghi chú...'"
                    :rows="3"
                    :max-rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <span class="font-weight-bolder">Tìm sản phẩm:</span>
            <b-row class="mb-4 mt-4 col-md-12" style="padding-left: 0px">
              <b-col class="col-md-6">
                <vue-autosuggest
                  v-model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @selected="onSelectedProduct"
                  :limit="10"
                  @input="onInputChangProduct"
                  :input-props="inputSearchProductProp"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProduct !== ''
                  "
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.productName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <span class="font-weight-bolder mb-4">Danh sách sản phẩm:</span>
            <b-table
              class="table-bordered table-hover col-md-12"
              :fields="fields"
              :items="listProductStock"
              v-if="renderComponent"
            >
              <template v-slot:cell(type)="row">
                <b-form-select v-model="row.item.type" size="sm">
                  <b-form-select-option value="1">
                    Tăng (+)
                  </b-form-select-option>
                  <b-form-select-option value="2">
                    Giảm (-)
                  </b-form-select-option>
                </b-form-select>
              </template>
              <template v-slot:cell(quantity)="row">
                <b-input
                  type="text"
                  size="sm"
                  class="input-style text-right"
                  v-model="row.item.quantity"
                ></b-input>
              </template>
              <template v-slot:cell(totalQuantityInStock)="row">
                <span
                  class="text-right"
                  v-text="row.item.totalQuantityInStock"
                ></span>
              </template>
              <template v-slot:cell(actions)="row">
                <v-icon
                  small
                  class="text-danger text-center"
                  @click="deleteItemInArray(row.item.id)"
                  v-b-tooltip
                  title="Xóa"
                  >mdi-delete</v-icon
                >
              </template>
            </b-table>

            <div class="mb-5 mt-5">
              <button
                v-show="listProductStock.length > 0"
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm button"
                @click="createStock"
              >
                Tạo phiếu cân kho
              </button>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import decounce from 'debounce';
import { TIME_TRIGGER } from './../../../utils/constants';
import { v4 } from 'uuid';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      form: {
        providerSelected: null,
      },
      isNew: true,
      errors: [],
      listInventories: [],
      totalPrice: 0,
      listProduct: [],
      listProductSearch: [],
      count: 0,
      listType: [
        { id: 1, name: 'Xuất trả hàng nhà cung cấp' },
        { id: 2, name: 'Xuất kho bán hàng' },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      fields: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tồn kho hiện tại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      timer: null,
      showOriginalModel: false,
      totalQuantity: 0,
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      isSearching: false,
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      renderComponent: true,
      description: '',
    };
  },
  validations: {},
  components: {
    KTCodePreview,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cân kho sản phẩm', route: 'inventory-balance' },
      { title: 'Tạo phiếu cân kho' },
    ]);
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let quantity = 1;
      this.searchProduct = option.item.productName;
      this.count++;
      let pro = {
        id: this.count,
        productId: this.selectedClassProduct.productId,
        productName: this.selectedClassProduct.productName,
        productCode: this.selectedClassProduct.productCode,
        totalQuantityInStock:
          this.selectedClassProduct.quantityInStock != null
            ? this.selectedClassProduct.quantityInStock
            : 0,
        quantity: quantity,
        type: '1',
      };
      this.listProductStock.push(pro);
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    createStock: async function () {
      this.isNew = false;

      let listPro = [];
      await this.listProductStock.forEach((element) => {
        let item = {
          productId: element.productId,
          quantity: element.quantity,
          type: element.type * 1,
          beforeQuantity: element.totalQuantityInStock,
        };
        listPro.push(item);
      });
      let data = {
        storeId: this.inventorySelected,
        listProduct: listPro,
        description: this.description,
      };
      if (listPro.length > 0) {
        //console.log(`Data: ${JSON.stringify(data)}`);
        ApiService.setHeader();
        ApiService.post('inventoryBalance', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  name: 'list-inventory-balance',
                });
              }, 1000);
            } else {
              this.makeToastFaile(data.message);
              this.isNew = true;
            }
          })
          .catch(({ response }) => {
            this.makeToastFaile(response.$error);
          });
      } else {
        this.makeToastFaile('Vui lòng chọn sản phẩm muốn cân kho!');
        this.isNew = true;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      )
        .then(({ data }) => {
          this.optionsProduct[0].data = [];
          const products = data.data;
          this.listProduct = products;
          products.map((element) => {
            this.optionsProduct[0].data.push(element);
          });
          this.filteredOptionsProduct = [
            {
              data: this.optionsProduct[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProduct) {
            let nextSearchProduct = this.searchProduct;
            this.fetchProduct(nextSearchProduct);
          }
        })
        .catch(() => (this.isSearching = false));
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    fetchProductImei() {
      this.listProduct = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store-imei?storeId=${this.inventorySelected}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei();
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    newId() {
      return v4();
    },
  },
};
</script>
